import {
  ListItem, ListItemButton, ListItemAvatar, Avatar, Box, ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Roles } from '../../../framework/constants/roles';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { authGuard } from '../../../utils/authGuardHelper';

export function UserItem({
  first_name, last_name, avatar, id,
}: { first_name: string, last_name: string, avatar: string, id: number }) {
  const fullName = `${first_name} ${last_name}`;
  const { user: currentUser } = useAuthStore();
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={`/admin/users/${id}`}
        disabled={!authGuard([Roles.ADMIN], currentUser?.role)}
        sx={{
          '&.Mui-disabled': {
            opacity: 1,
          },
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt={fullName}
            src={avatar || ''}
          />
        </ListItemAvatar>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <ListItemText primary={fullName} />
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
