import { useState } from 'react';
import {
  endOfWeek, getMonth, startOfWeek,
  eachDayOfInterval,
  endOfMonth,
  startOfMonth,
  addMonths,
  addWeeks,
} from 'date-fns';

export function useCalendar() {
  const today = new Date();
  const [firstOfMonth, setFirstOfMonth] = useState(startOfMonth(today));
  const [firstOfWeek, setFirstOfWeek] = useState(startOfWeek(today, { weekStartsOn: 1 }));
  const [view, setView] = useState<'week' | 'month'>('week');
  const start = view === 'month' ? startOfWeek(firstOfMonth, { weekStartsOn: 1 }) : firstOfWeek;
  const end = view === 'month' ? endOfMonth(firstOfMonth) : endOfWeek(firstOfWeek, { weekStartsOn: 1 });
  const currentMonth = view === 'month' ? getMonth(firstOfMonth) : getMonth(endOfWeek(firstOfWeek, { weekStartsOn: 1 }));
  const days = eachDayOfInterval({ start, end });

  const changeStart = (direction: number) => {
    if (view === 'month') {
      setFirstOfMonth(addMonths(firstOfMonth, direction));
    } else if (view === 'week') {
      setFirstOfWeek(addWeeks(firstOfWeek, direction));
    }
  };
  const setViewToMonth = () => {
    setView('month');
    setFirstOfMonth(startOfMonth(endOfWeek(firstOfWeek, { weekStartsOn: 1 })));
  };
  const setViewToWeek = () => {
    setView('week');
    setFirstOfWeek(startOfWeek(firstOfMonth, { weekStartsOn: 1 }));
  };

  return {
    start,
    end,
    today,
    firstOfMonth,
    firstOfWeek,
    setFirstOfMonth,
    setFirstOfWeek,
    view,
    setView,
    currentMonth,
    days,
    changeStart,
    setViewToMonth,
    setViewToWeek,
  };
}
