import {
  Box, Button, Tooltip,
} from '@mui/material';
import {
  format, isWeekend,
} from 'date-fns';
import { useState } from 'react';
import ReportIcon from '@mui/icons-material/Report';
import { tabValues } from '../../../framework/constants/CalendarTabs';
import { useCalendar } from '../../../framework/hooks/api/Calendar/useCalendar';
import { useSingleDate } from '../../../framework/hooks/api/Calendar/useSingleDate';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { useCalendar as useCalendarUtil } from '../../../framework/hooks/useCalendar';
import {
  isAfterFivePMdayBefore, isTodayBeforeFivePM, isTomorrowBeforeFivePMdayBefore, standardFormatFromDate,
} from '../../../utils/dateHelper';
import { CheckinsStats } from '../../molecules/checkins/CheckinsStats';
import { MenuCalendarItem } from '../../molecules/menus/MenuCalendarItem';
import { Calendar } from '../../organisms/calendar';
import { SingleDate } from '../../organisms/calendar/SingleDate';
import { CheckinCalendarItem } from '../../molecules/checkins/CheckinCalendarItem';
import { AuthGuard } from '../../utils/AuthGuard';
import { Roles } from '../../../framework/constants/roles';
import { Title } from '../../atoms/Title';
import { WeekCheckinButtons } from '../../molecules/checkins/WeekCheckinButtons';
import { CheckinAnswerType, CheckinAnswers } from '../../../framework/constants/CheckinAnswers';

export function CalendarPage() {
  const calendar = useCalendarUtil();
  const { start, end, today } = calendar;
  const {
    data, isLoading, isRefetching, updateCheckin, createLoading, updateLoading, createVariables, updateVariables, updateMultipleCheckins,
  } = useCalendar(start, end);
  const singleDateContext = useSingleDate();
  const {
    singleDate, openSingleDateDialog,
  } = singleDateContext;
  const {
    mealTypesData, mealTypesIsLoading,
  } = useMealTypes();
  const { YES, NO } = CheckinAnswers;

  const [goToTab, setGoToTab] = useState('');

  const openSingleDateDialogToMenu = (date: Date) => {
    if (data?.data?.data) {
      const selectedDate = data.data.data[standardFormatFromDate(date)];
      if (selectedDate.menu.items.length > 0) {
        setGoToTab(tabValues.MENU);
        openSingleDateDialog(date);
      }
    }
  };
  const openSingleDateDialogToUsers = (date: Date) => {
    setGoToTab(tabValues.USERS);
    openSingleDateDialog(date);
  };

  const getCalendarItemComponent = (day: Date) => {
    if (data?.data?.data && !isWeekend(day)) {
      const date = data.data.data[standardFormatFromDate(day)];
      if (date && mealTypesData) {
        return (
          <MenuCalendarItem menu={date.menu} mealTypes={mealTypesData?.data?.data || []} />
        );
      }
    }
    return null;
  };
  const getCalendarItemTitleComponent = (day: Date) => {
    if (data?.data?.data && !isWeekend(day)) {
      const stringDate = standardFormatFromDate(day);
      const date = data.data.data[stringDate];
      if (date) {
        return (
          <>
            <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]}>
              {date.allergens_match && (
                <Tooltip title="There are allergens matched for today">
                  <ReportIcon color="error" />
                </Tooltip>
              )}
            </AuthGuard>
            <Button
              onClick={() => openSingleDateDialogToUsers(day)}
              sx={{ p: 0 }}
              variant="contained"
              color="secondary"
              disableTouchRipple
              disableFocusRipple
            >
              <CheckinsStats checkins={date.checkins} />
            </Button>
          </>
        );
      }
    }
    return null;
  };
  const getCalendarItemActionsComponent = (day: Date) => {
    if (data?.data?.data && !isWeekend(day)) {
      const stringDate = standardFormatFromDate(day);
      const date = data.data.data[stringDate];
      const isCheckinLoading = (createLoading && !!createVariables?.dates?.find((d) => d === stringDate))
        || (updateLoading && updateVariables?.formData.date === stringDate);
      if (date) {
        const enableCheckOut = (isTodayBeforeFivePM(day, today) || (isTomorrowBeforeFivePMdayBefore(day, today)));
        return (
          <CheckinCalendarItem
            checkin={date.user_answer}
            isLoading={isCheckinLoading}
            date={stringDate}
            disabled={!isAfterFivePMdayBefore(day, today)}
            handleCheckinUpdate={updateCheckin}
            today={enableCheckOut}
          />
        );
      }
    }
    return null;
  };
  const weekUpdateCheckin = (answer: CheckinAnswerType, dates: string[]) => {
    updateMultipleCheckins(answer, dates);
  };
  const getWeekActionsComponent = (days: Date[]) => {
    const disabled = days.filter((d) => isAfterFivePMdayBefore(d, today) && !isWeekend(d));
    const dates = disabled.map((d) => standardFormatFromDate(d));
    return (
      <Box sx={{
        display: 'flex', flexDirection: 'column', p: 1, height: '100%',
      }}
      >
        <WeekCheckinButtons
          isLoading={false}
          disabled={dates.length === 0}
          datesTitle={days.length > 0
            ? `${format(days[0], 'dd. LLL')} - ${format(days.at(-1)!, 'dd. LLL')}`
            : ''}
          handleYes={() => weekUpdateCheckin(YES, dates)}
          handleNo={() => weekUpdateCheckin(NO, dates)}
        />
      </Box>
    );
  };
  const getDayBackgroundColor = (day: Date) => {
    if (data?.data?.data) {
      const stringDate = standardFormatFromDate(day);
      const date = data.data.data[stringDate];
      if (date?.user_answer?.answer === 'yes') {
        return 'success.main';
      }
      if (date?.user_answer?.answer === 'no') {
        return 'error.main';
      }
      return '';
    }
    return '';
  };
  return (
    <Box>
      <Title title="Calendar" />
      <Calendar
        loading={isLoading || isRefetching || mealTypesIsLoading}
        {...calendar}
        getRenderForDay={getCalendarItemComponent}
        getRenderForDayTitle={getCalendarItemTitleComponent}
        getRenderForDayActions={getCalendarItemActionsComponent}
        onDateClick={openSingleDateDialogToMenu}
        disableWeekends
        getRenderForWeekActions={getWeekActionsComponent}
        getDayBackgroundColor={getDayBackgroundColor}
      />
      {singleDate && <SingleDate {...singleDateContext} mealTypes={mealTypesData?.data?.data || []} initialTabValue={goToTab} />}
    </Box>
  );
}
