import {
  List, ListSubheader, Typography, Divider, Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Roles } from '../../../framework/constants/roles';
import { UserItem } from '../../molecules/users/UserItem';
import { AuthGuard } from '../../utils/AuthGuard';
import { SingleDateResponse } from '../../../framework/types/Calendar';
import { sortUserCheckins, searchUserCheckins } from '../../../utils/usersHelper';

export function UsersSingleDate({
  checkins, search, onExport, isExportLoading,
}: {
  checkins: SingleDateResponse['checkins'],
  search: string,
  onExport: () => void,
  isExportLoading: boolean,
}) {
  const sorted = sortUserCheckins(checkins);
  const { yes, no, unanswered } = searchUserCheckins(search, sorted);
  const isExportDisabled = yes.length === 0;
  return (
    <>
      <List
        sx={{
          bgcolor: 'secondary.main', borderRadius: 2, px: 1, py: 2, mb: 1, display: 'flex', flexDirection: 'column', gap: 1,
        }}
      >
        <ListSubheader sx={{
          bgcolor: 'secondary.main', pt: 2, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap',
        }}
        >
          <Typography>Yes</Typography>
          {!isExportDisabled && (
            <AuthGuard roles={[Roles.ADMIN]}><LoadingButton loading={isExportLoading} onClick={onExport}>Export</LoadingButton></AuthGuard>
          )}
          <Divider sx={{ width: '100%' }} />
        </ListSubheader>
        <Box sx={{ marginBottom: '20px' }}>
          {yes.map((user) => (
            <UserItem {...user} key={user.id} />
          ))}
        </Box>
      </List>
      <AuthGuard roles={[Roles.ADMIN]}>
        <List
          sx={{
            bgcolor: 'secondary.main', borderRadius: 2, px: 1, py: 2, mb: 1, display: 'flex', flexDirection: 'column', gap: 1,
          }}
        >
          <ListSubheader sx={{ bgcolor: 'secondary.main' }}>
            <Typography>No</Typography>
            <Divider />
          </ListSubheader>
          <Box sx={{ marginBottom: '20px' }}>
            {no.map((user) => (
              <UserItem {...user} key={user.id} />
            ))}
          </Box>
        </List>
        <List
          sx={{
            bgcolor: 'secondary.main', borderRadius: 2, px: 1, py: 2, mb: 1, display: 'flex', flexDirection: 'column', gap: 1,
          }}
        >
          <ListSubheader sx={{ bgcolor: 'secondary.main' }}>
            <Typography>Unanswered</Typography>
            <Divider />
          </ListSubheader>
          <Box sx={{ marginBottom: '20px' }}>
            {unanswered.map((user) => (
              <UserItem {...user} key={user.id} />
            ))}
          </Box>
        </List>
      </AuthGuard>
    </>
  );
}
