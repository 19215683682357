import {
  differenceInHours, format, isToday, isTomorrow, parse,
} from 'date-fns';

export const standardFormat = 'yyyy-MM-dd';

export const standardFormatFromDate = (date: Date) => format(date, standardFormat);

export const standardDateStringToDate = (string: string) => parse(string, standardFormat, new Date());

export const prettyDateFormat = 'dd MMM yyyy';

export const prettyFormatFromDate = (date: Date) => format(date, prettyDateFormat);

export const religiousDateFormat = 'MM-dd';

const hour = 17;

export const isAfterFivePMdayBefore = (date: Date, today: Date) => {
  const diff = differenceInHours(today, date);
  return diff <= 24 - hour;
};
export const isTodayBeforeFivePM = (date: Date, today: Date) => {
  const diff = differenceInHours(today, date);
  return isToday(date) && diff < hour;
};
export const isTomorrowBeforeFivePMdayBefore = (date: Date, today: Date) => {
  const diff = differenceInHours(today, date);
  return isTomorrow(date) && diff > hour - 24 && diff <= 0;
};
