// eslint-disable-next-line
import { http } from './http';
import { ParamObject } from './ParamObject';

export type ResponseWrapper<DataType> = {
  success: boolean;
  error?: string;
  response: Response;
  data?: DataType;
};
export class APIService<T> {
  private readonly url: string;

  constructor(
    endpoint: string,
  ) {
    this.url = `/${endpoint}`;
  }

  private static async ParseResponse<R>(response: Response, list = false) {
    const wrapper: ResponseWrapper<R> = {
      success: false,
      response,
    };
    if (response.ok) {
      wrapper.success = true;
      try {
        const parsedBody = await response.json();
        if (list) {
          wrapper.data = parsedBody;
        } else {
          wrapper.data = parsedBody.data;
        }
      } catch (e) {
        wrapper.data = undefined;
      }
    } else {
      wrapper.success = false;
      try {
        const parsedBody = await response.json();
        wrapper.data = parsedBody;
        if (typeof parsedBody?.message === 'string') {
          wrapper.error = parsedBody.message;
        }
        if (Array.isArray(parsedBody.message)) {
          wrapper.error = (parsedBody.message as string[]).join(';');
        }
      } catch (e) {
        wrapper.data = undefined;
      }
    }
    return wrapper;
  }

  private static async ParseExport(response: Response) {
    const wrapper: ResponseWrapper<Blob> = {
      success: false,
      response,
    };
    if (response.ok) {
      wrapper.success = true;
      try {
        const parsedBody = await response.blob();
        wrapper.data = parsedBody;
      } catch (e) {
        wrapper.data = undefined;
      }
    }
    return wrapper;
  }

  async getOne<P extends ParamObject>(
    id: string,
    params?: P,
  ): Promise<ResponseWrapper<T>> {
    return APIService.ParseResponse<T>(
      await http.get(`${this.url}/${id}`, params),
    );
  }

  async getAll<P extends ParamObject>(
    params?: P,
  ): Promise<ResponseWrapper<T>> {
    return APIService.ParseResponse<T>(
      await http.get(this.url, params),
      true,
    );
  }

  async get<D extends ParamObject>(
    params?: D,
  ): Promise<ResponseWrapper<T>> {
    return APIService.ParseResponse<T>(await http.get(this.url, params));
  }

  async post<D, R>(data: D): Promise<ResponseWrapper<R>> {
    return APIService.ParseResponse<R>(
      await http.post<D>(this.url, data),
    );
  }

  async update<D, R>(id: string, data: D): Promise<ResponseWrapper<R>> {
    return APIService.ParseResponse<R>(
      await http.patch<D>(`${this.url}/${id}`, data),
    );
  }

  async updateWhole<D, R>(id: string, data: D): Promise<ResponseWrapper<R>> {
    return APIService.ParseResponse<R>(
      await http.put<D>(`${this.url}/${id}`, data),
    );
  }

  async delete(id: string) {
    return APIService.ParseResponse(
      await http.delete(`${this.url}/${id}`),
    );
  }

  async export<D>(data: D): Promise<ResponseWrapper<Blob>> {
    return APIService.ParseExport(
      await http.post<D>(this.url, data),
    );
  }
}
