import { TabContext, TabPanel } from '@mui/lab';
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  SyntheticEvent, forwardRef, useEffect, useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { tabList, tabValues } from '../../../framework/constants/CalendarTabs';
import { Roles } from '../../../framework/constants/roles';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { SingleDateMeal, SingleDateResponse } from '../../../framework/types/Calendar';
import { MealType } from '../../../framework/types/MealTypes';
import { authGuard } from '../../../utils/authGuardHelper';
import { prettyFormatFromDate } from '../../../utils/dateHelper';
import { CheckinsStats } from '../../molecules/checkins/CheckinsStats';
import { UserItem } from '../../molecules/users/UserItem';
import { FetchUi } from '../../utils/FetchUi';
import { MenuSkeleton } from '../../utils/skeletons/MenuSkeleton';
import { MenuItem } from '../menus/MenuItem';
import { SearchInput } from '../../atoms/SearchInput';
import { UsersSingleDate } from './UsersSingleDate';

type Props = {
  singleDateDialog: boolean,
  singleDate: Date | null,
  closeSingleDateDialog: () => void,
  data: SingleDateResponse | undefined,
  isLoading: boolean,
  isRefetching: boolean,
  mealTypes: MealType[],
  initialTabValue?: string,
  onExport: (date: Date) => void,
  isExportLoading: boolean,
};

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="left" ref={ref} {...props} />);

export function SingleDate({
  singleDateDialog,
  singleDate,
  closeSingleDateDialog,
  data,
  isLoading,
  isRefetching,
  mealTypes,
  initialTabValue,
  onExport,
  isExportLoading,
}: Props) {
  const { user: currentUser } = useAuthStore();

  const [tabValue, setTabValue] = useState<string>(tabList[0]);
  useEffect(() => {
    if (initialTabValue) {
      setTabValue(initialTabValue);
    }
  }, [initialTabValue]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const [allergenDialog, setAllergenDialog] = useState(false);
  const [allergenDialogItem, setAllergenDialogItem] = useState<SingleDateMeal | null>(null);
  const openUsersAllergenDialog = (meal: SingleDateMeal) => {
    setAllergenDialog(true);
    setAllergenDialogItem(meal);
  };
  const closeUsersAllergenDialog = () => {
    setAllergenDialogItem(null);
    setAllergenDialog(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [search, setSearch] = useState('');
  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    setSearch('');
  }, [tabValue]);

  if (singleDate) {
    const checkins = data?.checkins;

    const count = checkins && {
      yes: `${checkins.yes.count}`,
      no: `${checkins.no.count}`,
      unanswered: `${checkins.unanswered.count}`,
    };
    return (
      <Dialog open={singleDateDialog} onClose={closeSingleDateDialog} scroll="paper" fullScreen={fullScreen} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }} elevation={0}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', pl: 4 }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
            }}
            >
              <Typography variant="h5">
                {prettyFormatFromDate(singleDate)}
              </Typography>
              {checkins && count && (
                <CheckinsStats checkins={count} />
              )}
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeSingleDateDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
            <TabContext value={tabValue}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                variant="scrollable"
                // scrollButtons
                allowScrollButtonsMobile
              >
                {tabList.map((tab) => (
                  <Tab
                    value={tab}
                    label={tab}
                    key={tab}
                    wrapped
                  />
                ))}
              </Tabs>
            </TabContext>
            {tabValue === tabValues.USERS && (
              <Box><SearchInput name="users_search" value={search} onChange={handleSearchChange} /></Box>
            )}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: '80vh' }}>
          <TabContext value={tabValue}>
            <TabPanel value={tabValues.MENU} sx={{ p: 0 }}>
              <FetchUi isLoading={isLoading || isRefetching} loadingUi={<MenuSkeleton />}>
                {data?.menu?.map((menu) => (
                  <MenuItem menu={menu} mealTypes={mealTypes} openUsersAllergenDialog={openUsersAllergenDialog} key={menu.id} />
                ))}
              </FetchUi>
            </TabPanel>
            <TabPanel value={tabValues.USERS} sx={{ p: 0 }}>
              <FetchUi isLoading={isLoading || isRefetching} loadingUi={<MenuSkeleton />}>
                {checkins && (
                  <UsersSingleDate
                    checkins={checkins}
                    search={search}
                    onExport={() => onExport(singleDate)}
                    isExportLoading={isExportLoading}
                  />
                )}
              </FetchUi>
            </TabPanel>
          </TabContext>
        </DialogContent>
        <Dialog open={allergenDialog} onClose={closeUsersAllergenDialog}>
          <DialogTitle>{allergenDialogItem?.name}</DialogTitle>
          <DialogContent>
            <List>
              {allergenDialogItem?.meal_allergens?.map((all) => (
                <div key={all.id}>
                  <ListSubheader>{all.name}</ListSubheader>
                  <Divider />
                  {all?.allergic_users?.map((u) => {
                    if (authGuard([Roles.ADMIN, Roles.MEAL_PROVIDER], currentUser?.role) || u.id === currentUser?.id) {
                      return (
                        <UserItem {...u} key={u.id} />
                      );
                    }
                    return null;
                  })}
                </div>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </Dialog>
    );
  }
  return null;
}
